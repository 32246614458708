* {
  box-sizing: border-box;
  color: #5594c0;
}

body {
  overflow-y: hidden;
}

a:link, a:hover, a:visited, a:active {
  color: #368ac7;
}

.homePage {
  opacity: 0;
  place-items: center;
  display: grid;
}

#me {
  grid-area: 1 / 1 / auto / 3;
}

#whatIDo {
  grid-area: 2 / 1 / auto / 3;
}

#enter {
  grid-area: 3 / 1 / auto / 3;
}

#education, #personal {
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}

body {
  background: #0c192c;
  min-height: 100vh;
}

.container {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
}

.bubbles {
  z-index: -1;
  display: flex;
  position: relative;
}

.bubbles span {
  animation: 15s linear infinite animate;
  animation-duration: calc(200s / var(--i));
  background: #4fc3dc;
  border-radius: 50%;
  width: 1.5em;
  height: 1.5em;
  margin: 0 4px;
  position: relative;
  box-shadow: 0 0 0 10px #4fc3dc44, 0 0 50px #4fc3dc, 0 0 100px #4fc3dc;
}

.bubbles span:nth-child(2n) {
  background: #ff2d7d;
  box-shadow: 0 0 0 10px #ff2d7d44, 0 0 50px #ff2d7d, 0 0 100px #ff2d7d;
}

@keyframes animate {
  100% {
    transform: translateY(100vh)scale(0);
  }

  50% {
    transform: translateY(50vh)scale(.25);
  }

  0% {
    transform: translateY(-10vh)scale(.5);
  }
}

@media (width <= 600px) {
  .container {
    width: 100vw;
    position: relative;
    overflow: scroll;
  }
}
/*# sourceMappingURL=index.687021c9.css.map */
