*{
    box-sizing: border-box; 
    color: rgb(85, 148, 192);
}

body{
    overflow-y: hidden; 
}


a:link, a:hover, a:visited, a:active{
    color: rgb(54, 138, 199);
}

.homePage{
    display: grid;
    place-items: center;
    opacity: 0;
}

#me{
    grid-column: 1 / 3;
    grid-row: 1;
}

#whatIDo{
    grid-column: 1 / 3;
    grid-row: 2;
}

#enter{
    grid-column: 1 / 3;
    grid-row: 3;
}

#education{
    margin-top: 1.5em;
    margin-bottom: 1.5em;
}

#personal{
    margin-top: 1.5em;
    margin-bottom: 1.5em;
}

body{
    min-height: 100vh;
    background: #0c192c;
}

.container{
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.bubbles{
    z-index: -1;
    position: relative;
    display: flex;
}

.bubbles span{
    position: relative;
    width: 1.5em;
    height: 1.5em;
    background: #4fc3dc;
    margin: 0 4px;
    border-radius: 50%;
    box-shadow: 0 0 0 10px #4fc3dc44,
    0 0 50px #4fc3dc,
    0 0 100px #4fc3dc;
    animation: animate 15s linear infinite;
    animation-duration: calc(200s / var(--i));
}

.bubbles span:nth-child(even){
    background: #ff2d7d;
    box-shadow: 0 0 0 10px #ff2d7d44,
    0 0 50px #ff2d7d,
    0 0 100px #ff2d7d;
}

@keyframes animate{
    100%{
        transform: translateY(100vh) scale(0);
    }
    50%{
        transform: translatey(50vh) scale(.25);
    }
    0%{
        transform: translateY(-10vh) scale(.5);
    }
}

@media (max-width: 600px){
    .container{
        position: relative;
        overflow: scroll;
        width: 100vw;
    }
}